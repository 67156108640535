import { Env } from "./environment.type";

export const environment: Env = {
  production: false,
  apiUrl: "https://api.taskayi.lemsainnov.com",
  firebase: {
    apiKey: "AIzaSyDOk9cbxkhAg8ePIEOOAH5BKJ19UioT0Ec",
    authDomain: "taskayi-dev.firebaseapp.com",
    projectId: "taskayi-dev",
    storageBucket: "taskayi-dev.appspot.com",
    messagingSenderId: "505277761475",
    appId: "1:505277761475:web:1ee28721ed02da38de9f76",
    measurementId: "G-0HNMCQ5E6E"
  }
};
