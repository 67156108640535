<header class="header-area bg-white border-bottom-radius box-shadow mb-25"
    [ngClass]="{'active': isToggled, 'sticky': isSticky}" [class.card-borderd-theme]="true">
    <div class="d-md-flex align-items-center justify-content-space-between">
        <div class="header-left-side d-flex align-items-center">
            <div class="burger-menu" (click)="toggle()" [ngClass]="{'active': isToggled}">
                <span class="top-bar"></span>
                <span class="middle-bar"></span>
                <span class="bottom-bar"></span>
            </div>
            <button mat-icon-button [matMenuTriggerFor]="appsMenu"
                class="menu-btn rounded-circle p-0 border-none position-relative">
                <i class="flaticon-menu-1"></i>
            </button>
            <mat-menu class="menu-dropdown" #appsMenu="matMenu" xPosition="after">
                <div class="menu-header d-flex align-items-center justify-content-space-between">
                    <h5 class="m-0">Taskayi Admin</h5>
                    <button type="button" class="bg-transparent p-0 border-none">Edit</button>
                </div>
           
                <div class="menu-footer">
                    <a routerLink="/" class="fw-medium position-relative">View All <i class="flaticon-chevron"></i></a>
                </div>
            </mat-menu>

        </div>
        <ul class="header-right-side d-flex align-items-center mt-0 mb-0">
    

            <li>
                <button mat-icon-button [matMenuTriggerFor]="profileMenu"
                    class="profile-btn main-color border-none bg-transparent position-relative">
                    <span class="fw-semibold">{{admin?.fullName ?? 'Admin'}}</span>
                </button>
                <mat-menu class="profile-menu-dropdown" #profileMenu="matMenu" xPosition="before">
                    <ul class="m-0">
                        <li>
                            <a routerLink="/profile">
                                <i class="ri-user-3-line"></i> Profile
                            </a>
                        </li>
                        <li>
                            <a routerLink="/account">
                                <i class="ri-settings-3-line"></i> Settings
                            </a>
                        </li>
                        <li>
                            <a class="profile-menu-dropdown-item" (click)="logout()">
                                <i class="ri-shut-down-line"></i> Logout
                            </a>
                        </li>
                    </ul>
                </mat-menu>
            </li>
        </ul>
    </div>
</header>